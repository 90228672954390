module.exports = {
    index: {
        grid: [{
                num: '12',
                text: 'Global Office',
            },
            {
                num: '100+',
                text: 'Trading products',
            },
            {
                num: '4',
                text: 'Authoritative supervision',
            },
            {
                num: '450+',
                text: 'Professional team',
            },
        ],
        product: [{
                title: 'Investment products',
                desc: 'CompanyNameEnglish To provide investors with more than 100 kinds of contract for differences trading products, including foreign exchange, precious metals, crude oil, stocks and indexes; All products can be traded on the same platform, bringing investors the best investment opportunities facing the global market.',
                imgUrl: require('@/assets/icon_forex-4.svg'),
            },
            {
                title: 'Capital security',
                desc: 'The customer capital and the company working capital are stored in separate bank accounts and are protected by the regulatory authorities。',
                imgUrl: require('@/assets/icon_lock.svg'),
            },
            {
                title: 'Global services',
                desc: 'CompanyNameEnglish We have 450 + professional staff all over the world, with more than 10 years of financial industry experience, providing professional localization services for all customers。',
                imgUrl: require('@/assets/icon_community.svg'),
            },
        ],
        experience: {
            title: 'Best trading experience',
            desc: 'We provide metatrader4 (MT4), one of the most widely used online trading platforms in the world. MT4 trading platform provides powerful chart analysis tools: more than 50 kinds of technical indicators and disk analysis tools. It is safe, reliable, easy to use and has the functional characteristics of high-level traders. It has become a standard platform for online trading. ',
            btn: 'Trade with MT4',
        },
        product2: [{
                title: 'Contract for difference',
                desc: 'Provide more than 40 currency pairs, including primary, secondary and other currency pairs',
                imgUrl: require('@/assets/icon_forex-4.svg'),
            },
            {
                title: 'noble metal',
                desc: 'Gold and silver trading is a very attractive alternative investment with high market liquidity',
                imgUrl: require('@/assets/icon_gold.svg'),
            },
            {
                title: 'energy',
                desc: 'It offers three popular trading commodities: Brent crude oil from UK, WTI light crude oil from USA and natural gas from USA',
                imgUrl: require('@/assets/icon_oil.svg'),
            },
            {
                title: 'index',
                desc: 'It provides more than ten kinds of indexes, including multi-national stock market index and US dollar index ',
                imgUrl: require('@/assets/icon_indices-1.svg'),
            },
            {
                title: 'shares',
                desc: 'More than 70 well-known companies listed in the United States and Europe',
                imgUrl: require('@/assets/icon_shares-1.svg'),
            },
        ],
        area: {
            title: 'Global offices on 4 continents',
            desc: 'Across Europe, South America, Asia and Africa',
        },
        honor: {
            title: 'Honorary awards',
            list: [{
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: 'Best institutional business broker',
                    desc: '2020 Financial Technology Award',
                    desc2: 'International wealth and finance Awards',
                },
                {
                    imgUrl: require('@/assets/Best-Affiliate-Program.png'),
                    title: 'Best Alliance Plan',
                    desc: '2020 DPA broker Award',
                    desc2: 'Fxdailyinfo',
                },
                {
                    imgUrl: require('@/assets/Best-News-Analysis-Provider.png'),
                    title: 'Best news and market analysis provider',
                    desc: '2020 DPA broker Award',
                    desc2: 'Fxdailyinfo',
                },
                {
                    imgUrl: require('@/assets/Best-Online-Trading-Services.png'),
                    title: 'Best online trading service award',
                    desc: '2020advfn international finance Award',
                    desc2: 'ADVFN',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: 'Best contract for difference broker',
                    desc: 'Rankia.com',
                    desc2: '',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: 'The most transparent broker',
                    desc: 'World contract for differences award 2019',
                    desc2: 'World contract for differences Award',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: 'Best education program',
                    desc: 'World contract for differences award 2019',
                    desc2: 'World contract for differences Award',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: 'The most reliable trading broker',
                    desc: 'UAE Business Awards 2019',
                    desc2: 'Middle East market magazine',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: 'Best contract for difference Customer Service Award',
                    desc: '2019 world financial contract for differences Award',
                    desc2: 'World Finance',
                },
            ],
        },
        sysPay: {
            title: 'World famous payment system',
            desc: 'Efficient and automated process, strict audit to ensure the safety of customers investment, diversified channels',
        },
        transaction: {
            title: 'The company is committed to meeting your demand for contracts for differences and foreign exchange transactions',
            desc: ' All transactions involve risks. Your loss may be greater than your income.',
            desca: 'Risk policy',
            text: 'Start real foreign exchange trading',
            btn: 'Open a real account',
        },
        brand: {
            title: ' CompanyNameEnglish   It is a business brand jointly used by many companies, including： ',
            text1: ' CompanyNameEnglish   Is a limited liability company based in Saint Vincent and the Grenadines with company number 333 LLC 2020. The registered address is：1st Floor, First St. Vincent Bank Bldg, James Street, Kingstown, St. Vincent and the Grenadines。 ',
            text2: ' CompanyNameEnglish . Authorized and regulated by the financial conduct authority (FCA), with a license number of 760555. The registered address is：1st Floor, 32 Cornhill, London EC3V 3SG, United Kingdom。 ',
            text3: ' CompanyNameEnglish . Authorized and regulated by the Cyprus securities and Exchange Commission (cysec), license number 285 / 15. The registered address is：159 Leontiou A’ Street, Maryvonne Building Office 204, 3022, Limassol, Cyprus。 ',
            text4: ' CompanyNameEnglish . Authorized and regulated by the Financial Services Commission (FSC) of the Republic of Mauritius, license number c118023331. The registered address is：Suite 207, 2nd Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebène, Republic of Mauritius。 ',
            text5: 'High risk investment warning: foreign exchange and contract for difference transactions are highly speculative and risky, and are not suitable for all investors. You may lose some or all of your investment. Therefore, the amount of your investment should be within your affordability. You should be aware of all the risks associated with margin trading. Please read the complete',
            risk: 'Risk disclosure policy',
            text6: ' Restricted area：CompanyNameEnglish   The website   information of is not targeted at the residents of British Columbia, Quebec and Saskatchewan in Canada, Japan, Taiwan, Democratic Peoples Republic of Korea (DPRK), Iran and the United States; And it will not be sent to or used by any person in any country or jurisdiction where the publication or use of the information violates local laws and regulations。 ',
        },
        icp: {
            text1: ' © All rights reserved ',
            text2: 'Qiongicp preparation No. 19002799',
            text3: 'Privacy policy',
            text4: 'Cookies policy',
            text5: 'Terms and conditions',
            text6: 'Website map',
            text7: 'Links',
        },
        bottomSwiper: [{
                text1: 'MetaTrader 4',
                text2: 'The world widely used trading tools, millisecond efficient execution speed and support EA automated trading, powerful chart analysis, add a variety of custom indicators, can be set check surplus stops effective risk management, one-stop trading hundreds kinds of products, provide a $100000 account full practice, support a variety of terminal equipment. ',
            },
            {
                text1: 'CompanyNameEnglish ',
                text2: 'CompanyNameEnglish  -- for you, the visionary trader to create CompanyNameEnglish  is by far the most professional and innovative handheld trading application, professional interface, intuitive and easy to use management tools, clear charts, zoom details, and many practical, unique features, will bring you a smooth trading experience. ',
            },
        ],
        topSwiper: [{
                text1: 'Official partner of premier league champions Manchester city',
                text2: 'Join hands with the strong to become the industry leader',
                text3: '',
            },
            {
                text1: 'Central bank level top level regulation',
                text2: 'Seven global regulatory licences',
                text3: 'Client funds are kept in isolation and the account security is guaranteed',
            },
            {
                text1: 'WebTrader- online trading',
                text2: 'So simple',
                text3: 'Created for novice traders',
            },
        ],
        headTitle: 'CompanyNameEnglish ',
        btnTran: 'Enter the trading hall',
        Welcome: 'Welcome',
        openAccount: 'Open a real account',
        PleaseLogin: 'login',
        lang: 'lang',
        tableBox: ['Product', 'Current price', 'Lowest', 'Highest'],
        introduce1: {
            title: 'Rich trading products',
            data: [{
                    title: 'Foreign exchange',
                    imgUrl: require('@/assets/pro_1.png'),
                    text1: 'More than 60 currency pairs',
                    text2: 'Leverage up to 400:1',
                    text3: '5x24h T+0 two-way transaction',
                },
                {
                    title: 'Index/Stock',
                    imgUrl: require('@/assets/pro_2.png'),
                    text1: 'More than 20 major global stock indexes',
                    text2: 'Over 70 globally recognized stocks',
                    text3: 'Conducive to portfolio diversification',
                },
                {
                    title: 'Precious metals',
                    imgUrl: require('@/assets/pro_3.png'),
                    text1: 'Most popular hedge',
                    text2: 'Leverage up to 200',
                    text3: 'Competitive point difference',
                },
                {
                    title: 'ETF',
                    imgUrl: require('@/assets/pro_4.png'),
                    text1: 'Basket of assets trading form',
                    text2: 'Multi-asset collection',
                    text3: 'Flexible lever up to 20:1',
                },
            ],
        },
        introduce2: {
            title: 'CompanyNameEnglish  makes trading more confident',
            content: 'CompanyFullNameEnglish . (REFERRED to as CompanyName , English name: CompanyNameEnglish ), the company is located in Beijing CBD financial services area, filed by the Asset Management Association of China Securities Investment.Focus on: the new Three board listed enterprises investment, listed companies refinancing, venture capital, venture capital and other capital operations services.Financial institutions that serve high net worth investors, provide venture capital for innovative small and medium-sized enterprises, and help domestic high-quality small and medium-sized enterprises realize industrial capital operation.',
        },
        introduce3: {
            title: 'Third party transaction view ',
            content: 'CompanyNameEnglish  accurately grasp the era to give huge investment opportunities, and brokers, Banks, securities institutions, private equity institutions, investment Banks, finance companies, law firms and other established long-term strategic partnership, with strict risk control mechanism, building diversified financial industrial chain, continuous selection for high net worth investors, configuration, healthy return of financial products, provide investors with professional, stable, safe and efficient financial services.',
        },
        learnMove: 'Learn more  ',
    },
    tabBar: ['Transaction', 'Profit', 'Support', 'info', 'Mine'],
    product: {
        home: 'home',
        Balance: 'Balance',
        money: 'YUAN',
        Withdrawal: 'W/D',
        Inpour: 'Inpour',
        AveragePrice: 'Average price',
        Time: 'Time',
        TimeLimit: 'Time Limit',
        Min: 'Min',
        Rate: 'Rate',
        Loss: 'Loss',
        Increase: 'Increase',
        Decrease: 'Decrease',
        O: 'O',
        H: 'H',
        L: 'L',
        C: 'C',
        smallMin: 'min',
        hour: 'hour',
        dayMin: 'day',
        Trend: 'Trend',
        LatestDeal: 'Latest deal',
        RecentTransaction: 'Recent transaction',
        User: 'User',
        Contract: 'Contract',
        Direction: 'Direction',
        Wager: 'Wager',
        Amount: 'Amount',
        Profit: 'Profit',
        unfinished: 'unfinished',
        goLogin: 'Please log in to view',
        ConfirmPurchase: 'Confirm purchase',
        ConfirmAllin: 'All amounts',
        Buy: 'Buy',
        ExpectedIncome: 'Expected income',
        assetType: 'asset type',
        BillingCycle: 'Billing cycle',
        OrderDirection: 'Order direction',
        MinimumBalance: 'Minimum balance',
        MinimumPurchase: 'Minimum purchase',
        upToBuy: 'up to buy',
        CurrentPrice: 'price',
        Confirm: 'Confirm',
        SuccessfulPurchase: 'Successful purchase',
        PleaseWait: 'Please wait',
        BuyPrice: 'Buy price',
        EndPrice: 'End price',
        settlementCycle: 'settlement cycle',
        profit: 'Profit',
        second: 's',
        InvestmentAmount: 'Investment amount',
        unlimited: 'unlimited',
        product: 'Products purchased',
        InLiquidation: 'In liquidation',
        profitText: 'Profit',
        lossText: 'loss ',
        success: 'Congratulations on the profits ',
    },
    profit: {
        title: 'financial management',
        synopsis: 'synopsis',
        TotalAmount: 'Total amount',
        YesterdayP: 'Yesterday`s earnings',
        cumulativeIncome: 'cumulative income',
        DailyInterest: 'Daily interest',
        TransferIn: 'Transfer In',
        TransferOut: 'Transfer Out',
        revenueRecord: 'revenue record',
        SingleDay: 'Single-day income',
        money: '(YUAN)',
        amount: 'amount',
        placeholder: 'Please enter the amount',
        confirm: 'Confirm',
        success: 'successful',
    },
    news: {
        title: 'News',
    },
    me: {
        CreditScore: 'Credit score',
        Inpour: 'Inpour',
        Withdrawal: 'Withdrawal',
        Team: 'My team',
        AccessLog: 'Access log',
        FundingDetails: 'Funding details',
        HistoryOrder: 'History order',
        BankCard: 'Bank card',
        RealName: 'Real name',
        ChangePassword: 'Change password',
        wlPassword: 'Modify withdrawal password',
        Share: 'Share',
        Language: 'Language',
        Logout: 'Logout',
        read: 'Certified',
        unread: 'Unauthenticated',
        close: 'close',
        codeText: 'Scan code to create a win-win situation',
        ShareText: 'Share QR code',
        copyLink: 'Copy share link',
        ModifyNickname: 'Modify Nickname',
        placeholder: 'Please enter a nickname',
        nickNakeConfirmBtn: 'Confirm',
        nickNakeCancelBtn: 'Cancel',
        service: 'Please contact customer service',
        success: 'Modification succeeded',
    },
    Team: {
        title: 'My Team',
        subordinateTitle: 'team',
        NickName: 'Nickname',
        TeamNum: 'Number of subordinates',
        ChildsProfit: 'Commission',
        ParentProfit: 'Contribution Commission',
        TotalProfit: 'Total Profit',
    },
    Inpour: {
        title: 'Inpour',
        Bank: 'Bank',
        digitalCurrency: 'Digital Currency',
        SelectAmount: 'Select amount',
        Amount: 'Amount',
        Submit: 'Submit',
    },
    rechargeService: {
        title: 'Online Bank Inpour',
        OpenBank: 'deposit bank',
        CardUserName: 'cardholder',
        InpourInformation: 'Inpour information',
        Amount: 'Amount',
        CardNo: 'Card No.',
        placeholder: 'Please contact support',
        Support: 'Support',
        Warm: 'Warm reminder：',
        line: '* First check the bank account information to be Inpoured, and then transfer the money through online banking or mobile banking. After the transfer is successful, the transfer information will be submitted truthfully. The financial specialist will add your payment in time after checking the information. ',
        line1: '* Please choose your peers to transfer as much as possible, and you can get to the account quickly. ',
        line2: '* After the Inpour is completed, keep the documents to facilitate verification and ensure your rights. ',
        line3: '* If recharging fails or does not arrive after recharging, please contact online support service for help. ',
    },
    Withdrawal: {
        title: 'Withdrawal',
        titleInfo: 'Withdrawal information',
        unBind: 'Please bind bank card',
        unSWIFTCode: 'Please bind SWIFT Code',
        AccountBalance: 'Withdrawable amount',
        cashBalance: 'Cash balance',
        RrozenAmount: 'Rrozen amount',
        PRNA: 'Please real name authentication',
        RNA: 'Real name authentication',
        WithdrawAmount: 'Withdraw amount',
        WithdrawPassword: 'Withdraw password',
        Submit: 'Submit',
        placeholder: 'Enter Withdraw password',
        placeholder1: 'Withdraw money charged ',
        placeholder2: '%. Total is ',
        placeholder3: '',
        cardNo: 'Withdraw card number',
        RealName: 'Real name',
        mobile: 'Mobile number',
        IDNumber: 'ID number',
        CertificateAddress: 'Certificate address',
        CurrentAddress: 'Current address',
        tips: 'reminder',
        tipsMessage: 'You have not set the withdrawal password, go to set it now?',
        AmountMessage: 'Please enter the amount',
        PassMessage: 'Please enter the correct withdrawal password',
        CommissionText: 'The actual amount is',
    },
    Accesslog: {
        title: 'Access log',
        Inpour: 'Inpour',
        Withdrawal: 'Withdrawal',
        Amount: 'Amount',
        type: 'type',
        Time: 'Time',
        Status: 'Status',
    },
    statusLang: {
        Review: 'Review',
        Success: 'Success',
        Fail: 'Fail',
    },
    FundingDetails: {
        title: 'Funding details',
        Upper: 'Inpour',
        Lower: 'Withdrawal',
        Bet: 'Bet',
        Win: 'Liquidation',
        BalanceToFinance: 'Balance To Finance',
        FinanceeToBalance: 'Financee To Balance',
        CashProfit: 'Withdrawal income of subordinates',
        money: ' YUAN',
    },
    history: {
        title: 'History order',
        Contract: 'Contract',
        Amount: 'Amount',
        Time: 'Time',
        Details: 'Details',
        Profit: 'Profit',
        money: ' YUAN',
        unfinished: 'unfinished',
    },
    bank: {
        title: 'Bank card',
        editTitle: 'Bind bank card',
        accountName: 'account name',
        opening: 'opening bank',
        point: 'opening point',
        card: 'card number',
        DigitalCurrency: 'Digital Currency',
        placeholder1: 'enter account name',
        placeholder2: 'enter opening bank',
        placeholder3: 'enter opening point',
        placeholder4: 'enter card number',
        usdt: 'USDT address (not required)',
        btc: 'BTC address (not required)',
        eth: 'ETH address (not required)',
        submit: 'submit',
        update: 'update',
        info: 'Note: for modification, please contact customer service',
    },
    SWIFTCode: {
        CardUserName: 'account name',
        Area: 'address',
        DetailArea: 'detail address',
        OpenBank: 'location of bank',
        CardNo: 'account number',
        usdt: 'enter SWIFT Code',
        btc: 'enter account',
        eth: 'enter',
        placeholder1: 'enter account name',
        placeholder2: 'enter address',
        placeholder3: 'enter detailed address',
        placeholder4: 'enter the location of the bank',
        submit: 'submit',
        info: 'Note: for modification, please contact customer service',
    },
    withPass: {
        title: 'Withdraw password',
        OldPasswordle: 'Old password',
        NewPassword: 'New password',
        ConfirmPassword: 'Confirm password',
        placeholder: 'Please enter the old password',
        placeholder1: 'Please enter a new password',
        placeholder2: 'Confirm new password',
        confirm: 'Confirm',
    },
    authentication: {
        title: 'Real Name Authentication',
        RealName: 'Real name',
        Mobile: 'Mobile',
        IDNumber: 'ID number',
        CertificateAddress: 'Certificate address',
        CurrentAddress: 'Current address',
        warnInfo: '* The real name information can only be filled in once, please fill in the truth',
        Confirm: 'Confirm',
        placeholder: 'Please enter your real name',
        placeholder1: 'Please enter your mobile phone number',
        placeholder2: 'Please enter ID number',
        placeholder3: 'Please enter the ID address',
        placeholder4: 'Please enter the current address',
        message: 'Please fill in the complete form',
        message1: 'Please enter the correct mobile phone number',
        success: 'Real name success',
    },
    login: {
        title: 'Login',
        lang: 'Lang',
        Account: 'Account',
        Password: 'Password',
        btn: 'Login',
        fail: 'Please complete the form',
        ForgotPassword: 'Forgot password',
        NoAccount: 'No account yet？',
        Registration: 'Registration',
        OtherLogin: 'Other login',
        success: 'Login successful',
        ageent: 'Read and agree to the',
        ageentTitle: 'service agreement',
        ageentFail: 'Please read and check the service agreement',
        renzheng: 'Hong Kong Network Security Authority Certification',
        beian: 'Jingongwang Anbei No. 11010202000001',
    },
    register: {
        title: 'Registration',
        lang: 'Lang',
        Account: 'Account',
        PhoneNumber: 'Mobile',
        Password: 'Password',
        ConfirmPassword: 'Confirm password',
        InvitationCode: 'Invitation code',
        btn: 'Registration',
        haveAccount: 'Already have an account？',
        Login: 'Login',
        placeholder: 'Please enter the user name',
        placeholder1: 'Please enter the password',
        placeholder2: 'Please enter the password again',
        placeholder3: 'Please enter the invitation code',
        placeholder4: 'Please enter the mobile',
        success: 'login was successful',
        message: 'Password at least 6 digits',
        message1: 'Confirm Password at least 6 digits',
        message2: 'The two passwords do not match',
    },
    public: {
        SubmittedSuccessfully: 'Submitted successfully',
        select: 'Please select',
        cancel: 'Cancel',
        confirm: 'Confirm',
        RefreshSucceeded: 'Refresh succeeded',
        noMoreData: 'There is no more data',
        pullingText: 'Drop down to refresh ...',
        ReleaseText: 'Release to refresh ...',
        loadingText: 'Loading ...',
    },
    xpgyHome: {
        title: 'Shanghai Xinping Public Welfare Foundation',
        articleTitle: 'The 5th & 6th Meeting of the 1st Board of Directors of Shanghai Xinping Foundation in 2021 was successfully held',
        articleContent1: 'On the afternoon of December 2nd, the 5th & 6th meeting of the first session of the Board of Directors of Shanghai Xinping Foundation in 2021 (hereinafter referred to as the "Board Meeting") was successfully held in the conference room on the 12th floor of 555 Building in Shanghai. Shanghai Xinping Public Welfare Foundation Chairman Lai Yingsheng, Vice Chairman Wu Wei, board members Wang Guomin, He Feifang attended the meeting, supervisors Shen Tu Yuanyan, Jin Xingxin attended, Zhou Yeru, Zhang Yi, Xu Xinping attended the meeting. ',
        articleContent2: 'In 2021, under the guidance of the Shanghai Civil Affairs Bureau and the leadership of the Board of Directors, Shanghai Xinping Public Welfare Foundation has completed 4 special projects, 2 facilities and service points will be launched soon, and reached cooperation with the Shanghai True Love Dream Foundation to conduct joint fundraising action, and the annual work results have been highly recognized by the Foundation Management Office of the Civil Affairs Bureau. At the same time, steadily promote the establishment of the official website of the Foundation, the rating of 3A social organizations and the certification of tax exemption status of non-profit organizations, gradually improve the staffing and team structure, and plan to improve the comprehensive business ability and professional skills of the team in 2022, actively provide support for those who meet the purpose of the foundation public welfare services, and further do a good job of information disclosure. Do a good job in the budget of funds, ensure that the next year management fees are used rationally, and strive to meet the requirements of the competent authorities for the foundation. At this meeting, the Board of Directors and the Supervisory Board heard the Secretariat report on the work of the Foundation in 2021 and the work plan for 2022, and affirmed the work results of the Foundation. ',
        articleContent3: 'The Board meeting reviewed and approved the adjustment of the Board of Directors and the Board of Supervisors, and appointed Ms. Zhou Yiru as the secretary general of Shanghai Xinping Public Welfare Foundation and Ms. Zhang Yi as the deputy secretary general; Reviewed and approved the revision of the constitution of the Foundation, discussed and reviewed the rules and regulations of the foundation. ',
        articleContent4: 'At the meeting, the directors have offered suggestions and suggestions, proposed that the heart Apple Foundation should absorb more professionals, do a good job of project localization, deep cultivation of the community, and lay a solid foundation for projects in Shanghai, not only to play the advantages of online fundraising, but also to tap more offline enterprises for donations. ',
        articleContent5: 'Finally, Chairman Lai Yingsheng said that in the next work, he will connect with more corporate resources, increase the project space of the foundation by raising funds, on the other hand, strengthen the staff team building, arrange the team ability training and atmosphere construction, and stabilize the team cohesion, I hope that everyone can be a rope, do a good job of the foundation, and further activate the charity atmosphere in Shanghai. Enhance industry awareness, give full play to the advantages of resources and platforms, and jointly safeguard the high-quality development of the foundation itself.',
        footer1: 'Shanghai Xinping Foundation ',
        footer2: 'Shanghai ICP 2021034925',
        footer3: 'Shanghai Public Network Anbei 31010602006588',
        footer4: 'Technical Support: Hangzhou Yingpai Technology Co., LTD',
    },
}